import { NavigationGuardNext, Route } from "vue-router";
import { RouteName } from "@/router/router-builder";
import { useOnBoardingStore } from "@/application/store/on-boarding.store";

export default function addClusterGuard (to: Route, _from: Route, next: NavigationGuardNext) {
  const onboardingStore = useOnBoardingStore()

  if (!onboardingStore.availableSteps.includes(to.name as RouteName)) {
    return next({ name: RouteName.AddClusterConfigure })
  }

  next()
}
