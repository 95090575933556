import { trackEvent } from "@/analytics/index";
import { DebounceMode, KeyedDebouncer } from "@/utils/keyed-debouncer"

export enum TrackableObject {
  Service = "Service",
  Node = "Node",
  Silence = "Silence",
  Comparison = "Comparison",
  Account = "Account",
  AccountDeleteModal = "Account Delete Modal",
  SoftPaywallButton = "Soft Paywall Button",

  NewVersionAlert = "New Version Alert",
  NewVersion = "New Version",
  NewVersionChangeLog = "New Version Change Log",
  NewVersionUpgradeDocs = "New Version Upgrade Docs",

  FeedbackPopup = "Feedback Popup",
  FeedbackPopupScheduleBtn = "Feedback Popup Schedule Button",
  FeedbackPopupRemindLaterBtn = "Feedback Popup Remind Later Button",

  TimelineView = "Timeline View",
  TimelineGrouping = "Timeline Grouping",
  TimelineVersion = "Timeline Version",
  TimelineGoBackToStartPointBtn = "Timeline Go Back To Start Point Button",
  TimelineFocusedEvent = "Timeline Focused Event",

  AlertTrialStarted = "Monetisation: Alert Trial Started",
  AlertTrialEnded = "Monetisation: Alert Trial Ended",
  AlertProPlanThankYou = "Monetisation: Alert Prop Plan Thank You",
  AlertProPlanStarted = "Monetisation: Alert Pro Plan Started",
  NewMonetisationModal = "Monetisation: New Monetisation Modal",
  UpgradeToProButton = "Monetisation: Upgrade To Pro Button",
  PaymentSetupButton = "Monetisation: Payment Setup Button",
  ManageSubscription = "Monetisation: Manage Subscription",

  GlobalFindingPopup = "Global Finding Popup",
  GlobalFindingPopupNextBtn = "Global Finding Popup Next Button",
  GlobalFindingPopupPrevBtn = "Global Finding Popup Previous Button",

  PopeyeBestPracticesDemoPage = "Popeye: Best Practices Demo Page",

  Feature = "feature",
  HolmesInstructionsEditorInstructions = 'holmes-instructions-editor/instructions',
  HolmesInstructionsEditorLinks = 'holmes-instructions-editor/links',

  ErrorCallout = "Error Callout",
  ErrorCalloutAction = "Error Callout Action"
}

export enum UserAction {
  Clicked = "Clicked",
  Deleted = "Deleted",
  Searching = "Searching",
  Renamed = "Renamed",
  Opened = "Opened",
  Closed = "Closed",
  Viewed = "Viewed",
  Skipped = "Skipped",
  RemindLater = "Remind Later",
  Visited = "Visited",
  Changed = "Changed",
  Hovered = "Hovered",
  Copied = "Copied",
  Typed = "Typed",
  Waited = "Waited",
  Submitted = "Submitted"
}

export enum FeatureType {
  AppListUseTable = "app-list:use-table",
  AppListUseTimeline = "app-list:use-timeline",
  AppListFreeTextFilter = "app-list:free-text-filter",
  AppListGroupBy = "app-list:group-by",
  AppListColumnFilters = "app-list:column-filters",
  AppFiltersKindChange = "app-list:filters:kind-change",
  AppFiltersVersionChange = "app-list:filters:version-change",
  AppFiltersStatusChange = "app-list:filters:status-change",
  AppFiltersAdvancedChange = "app-list:filters:advanced-change",
  AppFiltersKPIClick = "app-list:kpi-click",
  AppListSorting = "app-list:sorting",
  AppListPaging = "app-list:paging",
  AppListCSVExport = "app-list:csv-export",
  AppListTimelineUseQuickFilters = "app-list:timeline:use-quick-filters",
  AppDetailsViewPage = "app-details:view-page",
  AppDetailsTimelineUseQuickFilters = "app-details:timeline:use-quick-filters",
  GlobalTimelineUsedTimelineOrTable = "global-timeline:used-timeline-or-table",
  JobListUseTable = "job-list:use-table",
  JobListUseTimeline = "job-list:use-timeline",
  JobListCSVExport = "job-list:csv-export",
  JobListTimelineUseQuickFilters = "job-list:timeline:use-quick-filters",
  JobDetailsTimelineUseQuickFilters = "job-details:timeline:use-quick-filters",
  ClustersViewPage = "clusters:view-page",
  ClusterHealthViewPage = "cluster-health:view-page",
  ClusterHealthCSVExport = "cluster-health:csv-export",
  ClusterHealthFilterFreeTextChange = "cluster-health:free-text-change",
  ClusterHealthSortChange = "cluster-health:sort-change",
  ClusterHealthKPIChange = "cluster-health:kpi-click",
  ClusterHealthFilterHealthChange = "cluster-health:health-change",
  ClusterHealthFilterConnectionsChange = "cluster-health:connections-change",
  ClusterHealthFilterVersionsChange = "cluster-health:robusta-versions-change",
  ClusterHealthFilterK8SVersionsChange = "cluster-health:k8s-versions-change",
  ClusterHealthFilterIntegrationsChange = "cluster-health:integrations-change",
  AddClusterModalClickedRegenerateConfig = "add-cluster-modal:clicked-regenerate-config",
  AddClusterModalCopiedExtractValuesFile = "add-cluster-modal:copied-extract-values-file",
  AddClusterModalCopiedHelmAddRobusta = "add-cluster-modal:copied-helm-add-robusta",
  AddClusterModalCopiedHelmInstallRobusta = "add-cluster-modal:copied-helm-install-robusta",
  AddClusterModalCopiedSanityCheck = "add-cluster-modal:copied-sanity-check",
  ComparisonClustersCompared = "comparison-clusters:compared",
  ComparisonNamespacesCompared = "comparison-namespaces:compared",
  ComparisonResourceCompared = "comparison-resource:compared",
  EfficiencyPageView = "efficiency:page-view",
  EfficiencyDataLoadedSuccess = "efficiency:data-loaded-success", // FYI added these (Natan's comment on sheet)
  EfficiencyDataResultsValid = "efficiency:data-results-valid",
  EfficiencyUseTable = "efficiency:use-table",
  EfficiencyRecommendationsTableCSVExport = "efficiency:recommendations:csv-export",
  EfficiencyClusterCPUTableCSVExport = "efficiency:clusters-cpu-table:csv-export",
  EfficiencyClusterMemTableCSVExport = "efficiency:clusters-mem-table:csv-export",
  EfficiencyNamespaceCPUTableCSVExport = "efficiency:namespaces-cpu-table:csv-export",
  EfficiencyNamespaceMemTableCSVExport = "efficiency:namespaces-mem-table:csv-export",
  BestPracticesViewPage = "best-practices:view-page",
  BestPracticesCSVExport = "best-practices:csv-export",
  SilencesCreateSilence = "silences:create-silence",
  SilencesCreateSilenceSuccess = "silences:create-silence-success",
  SilencesFiltersStatusChange = "silences:filters:status-change",
  SilencesKPIClick = "silences:kpi-click",
  SilencesFreeTextFilter = "silences:filters:free-text-change",
  MetricsExplorerRunQuery = "metrics-explorer:run-query",
  MetricsExplorerRunQuerySuccess = "metrics-explorer:run-query-success",
  AiUseAnalyzeProblems = "ai:use-analyze-problems",
  AiUseAnalyzeProblemsSuccess = "ai:use-analyze-problems-success",
  AiUseLogSummary = "ai:use-log-summary",
  AiUseLogSummarySuccess = "ai:use-log-summary-success",
  ActionsUseLogs = "actions:use-logs",
  ActionsUseLogsSuccess = "actions:use-logs-success",
  ActionsUseShell = "actions:use-shell",
  ActionsUsePortForward = "actions:use-port-forward",
  ActionsUseRestart = "actions:use-restart",
  ActionsUseAppRestart = "actions:use-app-restart",
  ActionsUseJobDelete = "actions:use-job-delete",
  ActionsUseNodeCordon = "actions:use-node-cordon",
  ActionsUseNodeUncordon = "actions:use-node-uncordon",
  ActionsUseNodeShell = "actions:use-node-shell",
  SettingsAddUser = "settings:add-user",
  SettingsAddUserInviteSuccess = "settings:add-user-invite-success",
  GeneralUseNamespaceFilter = "general:use-namespace-filter",
  GeneralUseClusterFilter = "general:use-cluster-filter",
  SettingsDeleteUser = "settings:delete-user",
  SettingsDeleteAccount = "settings:delete-account",
  ClustersDeleteCluster = "clusters:delete-cluster",
  MonetizationViewUsage = "monetization: view-usage",
  MonetizationUpgradeToPro = "monetization: upgrade-to-pro",
  MonetizationUpgradeToProSuccess = "monetization: upgrade-to-pro-success",
  PresetsCreatePreset = "presets:create-preset",
  PresetsUsePreset = "presets:use-preset",
  PresetsDeletePreset = "presets:delete-preset",
  PresetsResetPreset = "presets:reset-preset",
  JobListFreeTextFilter = "job-list:free-text-filter",
  NodeListFreeTextFilter = "node-list:free-text-filter",
  NodeListCSVExport = "node-list:csv-export",
  GlobalTimelineCustomTimeRange = "global-timeline:custom-timerange",
  GlobalTimelinePeriod15min = "global-timeline:period-15min",
  GlobalTimelinePeriod1h = "global-timeline:period-1h",
  GlobalTimelinePeriod3h = "global-timeline:period-3h",
  GlobalTimelinePeriod6h = "global-timeline:period-6h",
  GlobalTimelinePeriod12h = "global-timeline:period-12h",
  GlobalTimelinePeriod24h = "global-timeline:period-24h",
  GlobalTimelinePeriodLastWeek = "global-timeline:period-last-week",
  GlobalTimelinePeriodLastMonth = "global-timeline:period-last-month",
  GlobalTimelineChangeFilters = "global-timeline:change-filters",
  GlobalTimelineAppGrouping = "global-timeline:app-grouping",
  GlobalTimelineCSVExport = "global-timeline:csv-export",
  // GlobalTimelinePodGrouping = "global-timeline:pod-grouping", // no need, we're removing the pod grouping (Natan's comment on sheet)
  GlobalTimelineEventStream = "global-timeline:event-stream",
  GlobalTimelineUseQuickFilters = "global-timeline:use-quick-filters",
  GlobalTimelineFilterPriorityChange = "global-timeline:filters:priority-change",
  GlobalTimelineFilterEventsChange = "global-timeline:filters:events-change",
  GlobalTimelineFilterSourcesChange = "global-timeline:filters:sources-change",
  GlobalTimelineFilterAppsChange = "global-timeline:filters:apps-change",
  GlobalTimelineFilterStatusesChange = "global-timeline:filters:statuses-change",
  GlobalTimelineFilterVersionChange = "global-timeline:filters:versions-change",
  GlobalTimelineFilterFreeTextChange = "global-timeline:filters:free-text-change",
  GlobalTimelineKPIClicked = "global-timeline:kpi-click",
  JobListStatusFilter = "job-list:status-filter",
  AddClusterModalCopyInstallCmdNormal = "add-cluster-modal:copy-install-cmd:normal-clusters",
  AddClusterModalCopyInstallCmdEKS = "add-cluster-modal:copy-install-cmd:eks",
  AddClusterModalCopyInstallCmdGKE = "add-cluster-modal:copy-install-cmd:gke",
  AddClusterModalCopyInstallCmdOpenShift = "add-cluster-modal:copy-install-cmd:open-shift",
  AddClusterModalCopyInstallCmdKindColima = "add-cluster-modal:copy-install-cmd:kind/colima",

  TrendAnalysisCSVExport = "trend-analysis:csv-export",
  TrendAnalysisUseQuickFilters = "trend-analysis:use-quick-filters",

  QuickLinksNavigateToLink = 'quick-link:navigate-to-link',

  HolmesEnableModalOpen = 'holmes-enable-modal:modal-open',
  HolmesEnableModalEnabled = 'holmes-enable-modal:holmes-enabled',

  HolmesService = 'ai:view-service-health-check',
  // AI Root Cause
  AiStartAlertRootCause = 'ai:start-alert-root-cause',
  AiStartAlertRootCauseStreaming = 'ai:start-alert-root-cause-streaming',
  AiFinishAlertRootCauseSuccess = 'ai:finish-alert-root-cause-success',
  AiFinishAlertRootCauseStreamingSuccess = 'ai:finish-alert-root-cause-streaming-success',
  AiFinishAlertRootCauseFailure = 'ai:finish-alert-root-cause-failure',
  AiFinishAlertRootCauseStreamingFailure = 'ai:finish-alert-root-cause-streaming-failure',
  AiAskRootCauseFollowUp = 'ai:ask-root-cause-follow-up',
  AiAskRootCauseFollowUpFailure = 'ai:ask-root-cause-follow-up-failure',
  AiAskRootCauseFollowUpSuccess = 'ai:ask-root-cause-follow-up-success',
  AiViewAlertRootCause = 'ai:view-alert-root-cause',
  AiViewAlertRootCauseSuccess = 'ai:view-alert-root-cause-success',
  AiViewAlertRootCauseFailure = 'ai:view-alert-root-cause-failure',

  AIStandaloneChatCardClick = 'ai:standalone-chat-card-click',
  AIStandaloneChatCardEditAndRun = 'ai:standalone-chat-card-edit-and-run',
  AIStandaloneChatCardEdit = 'ai:standalone-chat-card-edit',
  AIStandaloneChatInputInteraction = 'ai:standalone-chat-input-interaction',
  AIStandaloneChatChangedCluster = 'ai:standalone-chat-changed-cluster',
  AIStandaloneChatCreatedNewChat = 'ai:standalone-chat-created-new-chat',
  AIStandaloneChatReloadError = 'ai:standalone-chat-reload-error',
  AIStandaloneChatSuccessfulResponse = 'ai:standalone-chat-ai-successful-response',
  AIStandaloneChatErrorResponse = 'ai:standalone-chat-ai-error-response',
  AIStandaloneChatLeftPage = 'ai:standalone-chat-left-page',
  AIStandaloneChatQuestionAsked = 'ai:standalone-chat-question-asked',
  AIStandaloneChatFailure = 'ai:standalone-chat-failure',
  AIStandaloneChatDrawerIntegrationSorting = 'ai:standalone-chat-drawer-table-sorting',

  AIHealthCheckStart = 'ai:health-check-start',
  AIHealthCheckFailure = 'ai:health-check-failure',
  AIHealthCheckSuccess = 'ai:health-check-success',

  HolmesInstructionsEditorCreate = 'holmes-instructions-editor/create',
  HolmesInstructionsEditorUpdate = 'holmes-instructions-editor/update',
  HolmesInstructionsEditorUpdateDocuments = 'holmes-instructions-editor/update-documents',
  HolmesInstructionsFailed = 'holmes-instructions-editor/failed',
  HolmesInstructionsRemoved = 'holmes-instructions-editor/removed-instruction',
  HolmesInstructionsRunInvestigationClicked = 'holmes-instructions-editor/run-investigation-clicked',
  HolmesInstructionsResetClicked = 'holmes-instructions-editor/reset-clicked',

  CopyCodeBlock = 'copy-code-block',

  AIHealthCheckFollowUpStart = 'ai:health-check-follow-up-start',
  AIHealthCheckFollowUpSuccess = 'ai:health-check-follow-up-success',
  AIHealthCheckFollowUpFailure = 'ai:health-check-follow-up-failure',
}

export enum NewOnboardingFeatureEvents {
  SignUpWithProvider = 'signup:provider-clicked',
  SignUpGoToLoginClicked = 'signup:go-to-login-clicked',
  SignUpTermsAndConditionsClicked = 'signup:terms-clicked',
  LoginGoToSignUpClicked = 'login:go-to-signup-clicked',
  PageView = "on-boarding:page-view",
  InviteAccepted = 'on-boarding:invite-accepted',
  InviteRejected = 'on-boarding:invite-rejected',
  RequestAccess = 'on-boarding:access-requested',
  ChooseAccountNameNextButtonClicked = 'on-boarding:choose-account-name/next-button-clicked',
  ConfigureAccountChooseNoPrometheus = 'on-boarding:configure-account/choose-no-prometheus',
  ConfigureAccountNextButtonClicked = 'on-boarding:configure-account/next-button-clicked',
  AddFirstClusterChangedTab = 'on-boarding:add-first-cluster/changed-tab',
  AccountNameIsAvailable = 'on-boarding:account-name-is-available',
  AccountNameFilled = 'on-boarding:create/account-name-filled',
  AccountCreationError = 'on-boarding:create/error',
  BackButtonPressed = 'on-boarding:back-button-pressed',
  AccountNameChosenSuccessfully = 'on-boarding:create/account-name-chosen-successfully',
  SeeCommonErrorsClicked = 'on-boarding:see-common-errors/click',
  SeeCommonErrorsHelmInstallFailureClicked = 'on-boarding:see-common-errors/helm-install-failure-clicked',
  SeeCommonErrorsPodsNotRunningClicked = 'on-boarding:see-common-errors/pods-not-running-clicked',
  SeeCommonErrorsSeeFullHelpClicked = 'on-boarding:see-common-errors/see-full-help-clicked',
  StepperConfigureSettingsClicked = 'on-boarding:stepper/configure-settings-clicked',
  StepperInstallRobustaClicked = 'on-boarding:stepper/install-robusta-clicked',
  StepperVerifyInstallationClicked = 'on-boarding:stepper/verify-installation-clicked',
  StepperIngetratePrometheusClicked = 'on-boarding:stepper/integrate-prometheus-clicked',
  AccountCreatedSuccessfully = 'on-boarding:create/account-created-successfully',
  SlackAuthenticateClicked = 'on-boarding:create/slack/authenticate-clicked',
  SlackAuthCleared = 'on-boarding:create/slack/auth-cleared',
  SlackSaveClicked = 'on-boarding:create/slack/save-clicked',
  SlackError = 'on-boarding:create/slack/error',
  SlackSuccess = 'on-boarding:create/slack/success',
  SlackDrawerOpened = 'on-boarding:create/slack/drawer-opened',
  SlackDrawerClosed = 'on-boarding:create/slack/drawer-closed',
  SlackViewInstructionsClicked = 'on-boarding:create/slack/view-instructions-clicked',
  TeamsWebhookFilled = 'on-boarding:create/teams/webhook-filled',
  TeamsSaveClicked = 'on-boarding:create/teams/save-clicked',
  TeamsDrawerOpened = 'on-boarding:create/teams/drawer-opened',
  TeamsDrawerClosed = 'on-boarding:create/teams/drawer-closed',
  Download = 'on-boarding:install/download-config',
  CopyNormalCluster = 'on-boarding:install/copy-command-normal-clusters',
  CopyGKE = 'on-boarding:install/copy-command-gke',
  CopyEKS = 'on-boarding:install/copy-command-eks',
  CopyEKSStorage = 'on-boarding:install/copy-command-eks-storage',
  CopyOpenShift = 'on-boarding:install/copy-command-open-shift',
  CopyOpenShiftDownload = 'on-boarding:install/copy-command-download-open-shift',
  CopyKindColima = 'on-boarding:install/copy-command-kind/colima',
  CopyGetPods = 'on-boarding:install/copy-command-get-pods',
  VerifyConnection = 'on-boarding:install/verify-connection',
  FetchedClusters = 'on-boarding:install/verify-clusters',
  FetchedServices = 'on-boarding:install/verify-services',
  ErrorVerification = 'on-boarding:install/verify-connection-fail',
  VerificationTimeout = 'on-boarding:install/verify-connection-timeout',
  ContactSupport = 'on-boarding:install/install-contact-support',
  ContinueToPromAlert = 'on-boarding:verify-connection/continue-to-prom-alert-page',
  FinishedInstallSteps = 'on-boarding:install/finished-steps',
  TellUsWhatsWrongClicked = 'on-boarding:help/tell-us-whats-wrong-clicked',
  Finished = 'on-boarding:finished',
  TrialStarted = 'on-boarding:trial-started',
  GoToDocs = 'on-boarding:help/go-to-docs',

  GoToExternalPromAndAlertManagerDocs = 'on-boarding:prom-and-alert-mngr/go-to-docs',
  CheckPrometheusClicked = 'on-boarding:prom-and-alert-mngr/check-prom-clicked',
  PrometheusVerificationStatusUpdated = 'on-boarding:prom-and-alert-mngr/prom-status-updated',
  CheckAlertManagerAPIClicked = 'on-boarding:prom-and-alert-mngr/check-alert-mngr-api-clicked',
  AlertManagerAPIVerificationStatusUpdated = 'on-boarding:prom-and-alert-mngr/alert-mngr-api-status-updated',
  SimulateAlertClicked = 'on-boarding:prom-and-alert-mngr/simulate-alert-clicked',
  AlertSimulationVerificationStatusUpdated = 'on-boarding:prom-and-alert-mngr/alert-simulation-status-updated',
  PrometheusAndAlertManagerDoneClicked = 'on-boarding:prom-and-alert-mngr/done-clicked',
  PrometheusAndAlertManagerDoItLaterClicked = 'on-boarding:prom-and-alert-mngr/do-it-later-clicked',
  ClusterType = 'on-boarding:cluster-type',
  ConfigureIntegrationsNextButton = 'on-boarding:configure-integrations/next-button',
  OnboardingCopyCodeBlock = 'on-boarding:copy-code-block',
}

export type EventProps = Record<string, unknown>;

export class ObjectActionEvent {
  object: TrackableObject;
  action: UserAction;
  props?: EventProps;
  isDebug?: boolean;

  constructor(
    object: TrackableObject,
    action: UserAction,
    props?: EventProps,
    isDebug?: boolean
  ) {
    this.object = object;
    this.action = action;
    this.props = props;
    this.isDebug = isDebug;
  }
}

export function trackObjectActionEvent(event: ObjectActionEvent): void {
  const eventName = [event.object, event.action].join(" "); // For example: "Service Clicked"

  if (event.isDebug) {
    console.log(eventName, event.props);
  } else {
    trackEvent(eventName, event.props);
  }
}

// ========================= Feature tracking =========================

/** Key based debouncer - indexes debounce functions by keys. */
const keyedDebouncer = new KeyedDebouncer<FeatureType | NewOnboardingFeatureEvents>()

/**
 * Tracks feature usage.
 * Supports debounced-tracking by feature name.
 */
export function trackFeature(
  featureName: FeatureType | NewOnboardingFeatureEvents,
  action: UserAction, // Not used. It's only for Backwards compatibility.
  props?: Record<string, any>,
  opts?: {
    wait?: number // If > 0, tracking will be debounced.
    maxWait?: number // Max wait in case debounce applies.
    debounceMode?: DebounceMode
    debug?: boolean
  }
): void {
  if(!opts?.wait)
    _track(featureName, props, opts?.debug)
  else {
    const debouncedTrack = keyedDebouncer.debounce(
      featureName,
      _track,
      opts.wait,
      opts?.maxWait,
      opts?.debounceMode
    )
    debouncedTrack(featureName, props, opts?.debug)
  }
}

function _track(feature: FeatureType | NewOnboardingFeatureEvents, props?: Record<string, any>, debug = false) {
  if(debug) {
    console.log(new Date(), feature, props)
  } else {
    trackEvent(TrackableObject.Feature, { ...(props ?? {}), feature_name: feature }) // For feature comparison in Tableau
    trackEvent(feature, props) // For features in PostHog that don't allow filtering by properties
  }
}
