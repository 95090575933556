import VueRouter from 'vue-router'
import { trackPageView } from '@/analytics'
import supabase from '@/store/supabase-client'
import { deserialize } from '@/composables/use-sync-query-params'
import { decodeDatetimeFilter } from "@/modules/codecs/datetime-filter-codec"
import { QueryParam } from "@/views/query-params"
import { queryParamToProp } from "@/router/utils"
import { Service } from '@/store/services/types'
import { Service as ServiceV2 } from '@/store/services/v2/types'

import { Job } from '@/store/jobs'
import { KubeNode } from '@/store/nodes/types'
import {RouteName, RouterBuilder} from "@/router/router-builder"
import {
  authGuard,
  accountBasedRoutesGuard,
  accountQueryParamCleanupGuard,
  supabaseHashCleanupGuard,
  globalModalGuard
} from "@/router/global-guards"
import {addClusterGuard, prohibitIfAuthenticatedGuard} from "@/router/route-guards"
import { isSlackInvestigation } from './global-guards/01.auth.guard'

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  routes: [
    {
      name: RouteName.Error,
      path: '/error',
      component: () => import('@/views/Error.vue'),
      props: (route) => ({
        errorType: route.params.errorType,
        context: {
          ...(route.params.hasOwnProperty('context') && typeof route.params.context === 'string' ? JSON.parse(route.params.context) : {})
        }
      }),
      beforeEnter: (to, _from, next) => {
        if(!to.params.hasOwnProperty('errorType')) {
          next({ name: RouteName.Apps })
          return
        }

        next()
      }
    },
    {
      name: RouteName.MobileAccessView,
      path: '/mobile-access',
      component: () => import('@/views/MobileAccess.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      name: RouteName.Login,
      path: '/login',
      component: () => import('@/views/LoginView.vue'),
      beforeEnter: prohibitIfAuthenticatedGuard
    },
    {
      name: RouteName.OKTAlogin,
      path: '/okta',
      component: () => import('@/views/OKTALoginView.vue'),
      beforeEnter: prohibitIfAuthenticatedGuard
    },
    {
      name: RouteName.SignUp,
      path: '/signup',
      component: () => import('@/views/SignUpView.vue'),
      beforeEnter: prohibitIfAuthenticatedGuard
    },
    {
      name: RouteName.Invitation,
      path: '/invitation',
      component: () => import('@/views/on-boarding/AccountInvitedView.vue'),
      meta: {
        requiresAuth: true
      },
      beforeEnter: (to, _, next) => {
        if(
          to.query.hasOwnProperty('email') &&
          typeof to.query.email === 'string' &&
          to.query.email !== supabase.email
        ) {
          return next({
            name: RouteName.Error,
            params: {
              errorType: 'WRONG_INVITATION_USER',
              context: JSON.stringify({
                currentEmail: supabase.email,
                invitationEmail: to.query.email
              })
            }
          })
        }

        if(supabase.invitations.length === 0)
          return next(RouterBuilder.getAppsRoute())

        next()
      }
    },
    {
      name: RouteName.AccountAccessRequest,
      path: '/account-access-request',
      component: () => import('@/views/on-boarding/AccountAccessRequestView.vue'),
      meta: {
        requiresAuth: true
      },
      beforeEnter: async (to, _, next) => {
        // User Trying to access account from slack to which he doesn't have access
        if (isSlackInvestigation.value && !supabase.accounts.find(a => a.id === to.query?.accId)) {
          return next()
        }

        // If user already has access to the account redirect from the route to apps
        if (supabase.accounts.find(a => a.name === to.query?.accName)) {
          return next({
            path: `/${to.query.accName}`
          })
        }
        // Route without query param, redirect to the start of flow
        if (!to.query?.accName) {
          return next({ path: '/' })
        }

        next()
      }
    },
    {
      name: RouteName.CreateAccount,
      path: '/create-account',
      meta: {
        requiresAuth: true
      },
      component: () => import('@/views/on-boarding/CreateAccountView.vue'),
    },
    {
      path: "/:account?",
      component: () => import('@/views/Auth.vue'),
      meta: {
        requiresAuth: true,
        accountBased: true,
      },
      children: [
        {
          name: RouteName.TrendAnalysis,
          path: 'trends',
          component: () => import('@/features/trend-analysis/TrendAnalysis.vue'),
          props: (route) => {
            return {
              ...queryParamToProp(QueryParam.Dates, route, decodeDatetimeFilter),
            }
          }
        },
        {
          name: RouteName.Apps,
          path: 'apps',
          component: () => import('@/views/services/Services.vue'),
          beforeEnter: (route, _, next) => {
            const cluster = deserialize(<string>route.query.cluster)
            const namespace = deserialize(<string>route.query.namespace)
            const kind = deserialize(<string>route.query.type)
            const name = deserialize(<string>route.query.name)
            if (cluster && namespace && kind && name) {
              next({
                name: RouteName.AppDetails,
                params: {name, ...route.params},
                query: {cluster, namespace, kind}
              })
              return
            }
            next()
          }
        },
        {
          name: RouteName.AppDetails,
          path: 'app/:name',
          component: () => import('@/views/services/ServiceDetails.vue'),
          props: (route) => {
            return {
              cluster: route.query.cluster,
              namespace: route.query.namespace,
              kind: route.query.kind,
              name: route.params.name
            }
          }
        },
        {
          name: RouteName.GlobalTimeline,
          path: 'graphs',
          component: () => import('@/views/GlobalTimeline.vue'),
          props: route => ({
            ...queryParamToProp(QueryParam.Dates, route, decodeDatetimeFilter),
            ...queryParamToProp(QueryParam.Clusters, route),
            ...queryParamToProp(QueryParam.Namespaces, route),
            ...queryParamToProp(QueryParam.Apps, route),
            ...queryParamToProp(QueryParam.Events, route),
            ...queryParamToProp(QueryParam.Names, route), // Backward compatibility. For future use Events param above.
            ...queryParamToProp(QueryParam.Priorities, route),
            ...queryParamToProp(QueryParam.Grouping, route),
            ...queryParamToProp(QueryParam.Focus, route),
            ...queryParamToProp(QueryParam.DrilldownTarget, route),
            ...queryParamToProp(QueryParam.Sources, route),
            ...queryParamToProp(QueryParam.QuickFilters, route),
            ...queryParamToProp(QueryParam.Filters, route),
            ...queryParamToProp(QueryParam.Search, route)
          }),
        },
        {
          path: 'holmes',
          component: () => import('@/views/holmes/HolmesView.vue'),
          children: [
            {
              name: RouteName.HolmesGPT,
              path: '',
              component: () => import('@/views/holmes/HolmesMain.vue')
            },
            {
              name: RouteName.HolmesChat,
              path: 'chat',
              component: () => import('@/views/holmes/HolmesChat.vue'),
            },
          ]
        },
        {
          name: RouteName.Jobs,
          path: 'jobs',
          component: () => import('@/views/jobs/Jobs.vue'),
          beforeEnter: (route, _, next) => {
            const cluster = deserialize(<string>route.query.cluster)
            const namespace = deserialize(<string>route.query.namespace)
            const name = deserialize(<string>route.query.name)
            if (cluster && namespace && name) {
              next({
                name: RouteName.JobDetails,
                params: {name, ...route.params},
                query: {cluster, namespace}
              })
              return
            }
            next()
          }
        },
        {
          name: RouteName.JobDetails,
          path: 'job/:name',
          component: () => import('@/views/jobs/JobDetails.vue'),
          props: (route) => {
            return {
              cluster: route.query.cluster,
              namespace: route.query.namespace,
              name: route.params.name
            }
          }
        },
        {
          name: RouteName.Nodes,
          path: 'nodes',
          component: () => import('@/views/nodes/Nodes.vue'),
          beforeEnter: (route, _, next) => {
            const cluster = deserialize(<string>route.query.cluster)
            const name = deserialize(<string>route.query.name)
            if (cluster && name) {
              next({
                name: RouteName.NodeDetails,
                params: {name, ...route.params},
                query: {cluster}
              })
              return
            }
            next()
          }
        },
        {
          name: RouteName.NodeDetails,
          path: 'node/:name',
          component: () => import('@/views/nodes/NodeDetails.vue'),
          props: (route) => {
            return {
              cluster: route.query.cluster,
              name: route.params.name
            }
          }
        },
        {
          name: RouteName.Comparison,
          path: 'comparison',
          component: () => import('@/views/comparison/Comparison.vue')
        },
        {
          name: RouteName.ClusterComparison,
          path: 'compare/cluster',
          component: () => import('@/views/comparison/Comparison.vue'),
          props: {
            compareType: 'cluster'
          }
        },
        {
          name: RouteName.NamespaceComparison,
          path: 'compare/namespace',
          component: () => import('@/views/comparison/Comparison.vue'),
          props: {
            compareType: 'namespace'
          }
        },
        {
          name: RouteName.ResourceComparison,
          path: 'compare/resources',
          component: () => import('@/views/comparison/ComparisonNew.vue')
        },
        {
          name: RouteName.Clusters,
          path: 'cluster-health',
          component: () => import('@/views/cluster-health/ClusterHealth.vue')
        },
        {
          name: RouteName.ClusterHealthDetails,
          path: 'cluster-health/:clusterName*',
          component: () => import('@/views/cluster-health/ClusterHealthDetails.vue'),
          props: true
        },
        {
          name: RouteName.SimulateAlert,
          path: 'simulate-alert/:clusterName*',
          component: () => import('@/views/simulate-alert/SimulateAlert.vue'),
          props: true
        },
        {
          name: RouteName.Alerts,
          path: 'alerts',
          component: () => import('@/views/Alerts.vue')
        },
        {
          name: RouteName.Silences,
          path: 'silences',
          component: () => import('@/views/silences/Silences.vue')
        },
        {
          path: 'silences/create',
          redirect: (to) => ({ name: RouteName.Silences, query: to.query })
        },
        {
          name: RouteName.MetricsExplorer,
          path: 'metrics-explorer',
          component: () => import('@/views/PrometheusQuery.vue'),
          props: (route) => ({cluster: route.query.cluster, query: route.query.query, time: route.query.time})
        },
        {
          name: RouteName.KRR,
          path: 'krr',
          component: () => import('@/views/KRR.vue')
        },
        {
          name: RouteName.Insights,
          path: 'insights/:scanId?',
          component: () => import('@/views/Insights.vue'),
          props: true
        },
        {
          name: RouteName.PlanAndUsage,
          path: 'plan-and-usage',
          component: () => import('@/views/PlanAndUsage.vue')
        },
        {
          name: RouteName.Settings,
          path: 'settings',
          component: () => import('@/views/Settings.vue')
        },
        {
          path: 'services',
          redirect: route => {
            const {clusters: qclusters, namespaces: qnamespaces, kind: qkind, name: qname, ...rest} = route.query
            const cluster = <string>JSON.parse(<string>qclusters)[0]
            const namespace = <string>JSON.parse(<string>qnamespaces)[0]
            const kind = <string>qkind
            const name = <string>qname
            return {
              name: RouteName.AppDetails,
              params: {name},
              query: {cluster, namespace, kind, ...rest}
            }
          }
        },
        {
          name: RouteName.WaitingForConnection,
          path: 'check-connection',
          component: () => import('@/views/WaitingForConnectionView.vue'),
        },
        {
          name: RouteName.AddCluster,
          path: 'add-cluster',
          component: () => import('@/views/add-cluster/AddClusterWizardView.vue'),
          redirect: () => ({ name: RouteName.AddClusterConfigure } ),
          children: [
            {
              name: RouteName.AddClusterConfigure,
              path: 'configure',
              component: () => import('@/views/add-cluster/ConfigureIntegrationsView.vue'),
            },
            {
              name: RouteName.AddClusterInstallRobusta,
              path: 'install-robusta',
              component: () => import('@/views/add-cluster/InstallRobustaView.vue'),
              beforeEnter: addClusterGuard
            },
            {
              name: RouteName.AddClusterVerifyInstallation,
              path: 'verify-installation',
              component: () => import('@/views/add-cluster/VerifyInstallationView.vue'),
              beforeEnter: addClusterGuard
            },
            {
              name: RouteName.AddClusterPrometheusAndAlertManager,
              path: 'prometheus-alertmanager',
              component: () => import('@/views/add-cluster/PrometheusAndAlertManagerWizardView.vue'),
              beforeEnter: addClusterGuard
            },
          ]
        },
        {
          path: '',
          redirect: (route) => ({ name: RouteName.Apps, params: { ...route.params } } )
        },
      ]
    },
    {
      name: RouteName.NotFound,
      path: '*',
      component: () => import('@/views/Page404.vue')
    }
  ],
})

const reloadOnce = () => {
  if(!(window as any)._reloadOnce) {
    (window as any)._reloadOnce = true
    window.location.reload()
  }
}
router.onError((error) => {
  // NOTE: https://github.com/vitejs/vite/issues/11804
  if(
    error.message.includes('Failed to fetch dynamically imported module') ||
    (error as any).type === 'loading-chunk'
  ) {
    console.warn(`Dynamic module failed to load: ${error.message}`)
    reloadOnce()
  } else {
    console.error('Router error:', error)
  }
})
window.addEventListener('vite:preloadError', () => {
  reloadOnce()
})

// Global before guards
router.beforeEach(authGuard)
router.beforeEach(accountBasedRoutesGuard)
router.beforeEach(accountQueryParamCleanupGuard)
router.beforeEach(supabaseHashCleanupGuard)
router.beforeEach(globalModalGuard)

// Global after guards
router.afterEach((to) => trackPageView(to.path))

export function goToService(service: Service) {
  router.push({
    name: RouteName.AppDetails,
    params: {
      account: supabase.accountName,
      name: service.name
    },
    query: {
      cluster: service.cluster,
      namespace: service.namespace,
      kind: service.type
    }
  })
}
export function linkToService(service: ServiceV2) {
  return `/app/${service.name}?cluster=${service.cluster}&namespace=${service.namespace}&kind=${service.kind}`;
}

export function goToJob(job: Job) {
  router.push({
    name: RouteName.JobDetails,
    params: {
      account: supabase.accountName,
      name: job.name
    },
    query: {
      cluster: job.cluster_id,
      namespace: job.namespace
    }
  })
}
export function linkToJob(job: Job) {
  return `/job/${job.name}?cluster=${job.cluster_id}&namespace=${job.namespace}`;
}

export function goToNode(node: KubeNode) {
  router.push({
    name: RouteName.NodeDetails,
    params: {
      account: supabase.accountName,
      name: node.name
    },
    query: {
      cluster: node.cluster_id
    }
  })
}
export function linkToNode(node: KubeNode) {
  return `/node/${node.name}?cluster=${node.cluster_id}`;
}

export function goToAccount(accountName: string) {
  window.location.href = `${window.location.origin}/${accountName}/apps`
}

export default router
