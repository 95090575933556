import { reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import { useNow } from '@vueuse/core'
import { IS_SELF_HOSTED } from '@/consts'
import supabase from '@/store/supabase-client'
import { PlanTypes } from '@/plan-and-billing/plan-and-billing.types'
import { getAccountBillingData } from '@/plan-and-billing/plan-and-billing.data'

interface BillingInfoState {
  plan: PlanTypes
  trialEndDate: Date | undefined
}

const getDefaultState = (): BillingInfoState => ({
  plan: 'free',
  trialEndDate: undefined
})

export const useBillingInfoStore = defineStore('billing-info', () => {
  const now = useNow({ interval: 1000 * 60 })

  const state = reactive(getDefaultState()) as BillingInfoState

  const plan = computed(() => {
    if(IS_SELF_HOSTED)
      return 'custom'
    if(['custom', 'pro'].includes(state.plan))
      return state.plan
    if(!!trialEndDate.value && trialEndDate.value.getTime() >= now.value.getTime())
      return 'trial'
    return 'free'
  })
  const trialEndDate = computed(() => state.trialEndDate)
  const isInTrial = computed(() => plan.value === 'trial')
  const trialIsOver = computed(() => !!trialEndDate.value && trialEndDate.value.getTime() < now.value.getTime())
  const isFree = computed(() => plan.value === 'free')

  async function update() {
    if(IS_SELF_HOSTED)
      return Promise.resolve()

    try {
      const info = await getAccountBillingData(supabase.accountId)
      state.plan = info.plan
      state.trialEndDate = info.trial_end ? new Date(info.trial_end) : undefined
    } catch(error) {
      // Do nothing
    }
  }

  function $reset() {
    Object.assign(state, getDefaultState())
  }

  return {
    plan,
    trialEndDate,
    isInTrial,
    trialIsOver,
    isFree,
    update,
    $reset
  }
})
