export const ENV_ENABLE_ROBUSTA_LOGIN = (window as any).ENV_ENABLE_ROBUSTA_LOGIN as boolean // Email
export const ENV_ENABLE_ROBUSTA_SIGNUP = (window as any).ENV_ENABLE_ROBUSTA_SIGNUP as boolean // Email
export const ENABLE_GOOGLE_LOGIN = (window as any).ENV_ENABLE_GOOGLE_LOGIN as boolean
export const ENABLE_GITHUB_LOGIN = (window as any).ENV_ENABLE_GITHUB_LOGIN as boolean
export const ENABLE_AZURE_LOGIN = (window as any).ENV_ENABLE_AZURE_LOGIN as boolean
export const ENABLE_KEYCLOAK_LOGIN = (window as any).ENV_ENABLE_KEYCLOAK_LOGIN as boolean
export const IS_SELF_HOSTED = (window as any).ENV_SELF_HOSTED as boolean
export const EULA_URL = "https://api.robusta.dev/eula.html"
export const TERMS_OF_SERVICE_URL = "https://home.robusta.dev/terms-of-use-for-robusta"
export const OAUTH_REQUEST_URL = "https://robusta-dev.typeform.com/to/nBfgblBj"
export const SSO_SIGNIN_DOMAIN = (window as any).ENV_SSO_SIGNIN_DOMAIN as string // Company SSO sign in domain.
export const SUPABASE_URL = (window as any).ENV_SUPABASE_URL as string
export const SUPABASE_KEY = (window as any).ENV_SUPABASE_KEY as string
export const RELAY_HOST = (window as any).ENV_RELAY_HOST as string
