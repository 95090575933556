import * as _ from "lodash"

export type DebounceMode = "leading" | "trailing" | "both"
type Func = (...args: any) => any

export class KeyedDebouncer<K> {
  protected map: Map<K, _.DebouncedFunc<Func>>

  constructor() {
    this.map = new Map()
  }

  public debounce(key: K, fn: Func, wait: number, maxWait?: number, mode?: DebounceMode) {
    if(this._has(key))
      return this._get(key)!

    const debouncedFn = _.debounce(fn, wait, this._getLodashSettings(maxWait, mode))
    this._set(key, debouncedFn)
    return debouncedFn
  }

  protected _has(key: K) {
    return this.map.has(key)
  }

  protected _get(key: K) {
    return this.map.get(key)
  }

  protected _set(key: K, debouncedFn: _.DebouncedFunc<Func>) {
    this.map.set(key, debouncedFn)
  }

  protected _getLodashSettings(maxWait?: number, mode?: DebounceMode): _.DebounceSettings {
    const invocationOrder = mode === "both"
      ? { leading: true, trailing: true }
      : mode === "leading"
        ? { leading: true, trailing: false }
        : { leading: false, trailing: true }

    if(maxWait)
      return { ...invocationOrder, maxWait }
    return invocationOrder
  }
}