import { DatetimeFilter } from "@/store/DatetimeFilter"
import { QueryParam } from "@/views/query-params"
import { serialize } from "@/composables/use-sync-query-params"
import { encodeDatetimeFilter } from "@/modules/codecs/datetime-filter-codec"
import supabase from '@/store/supabase-client'

export enum RouteName {
  Login = "Login",
  SignUp = "SignUp",
  OKTAlogin = "OKTA Login",
  CreateAccount = "CreateAccount",
  AddCluster = 'Add Cluster',
  AddClusterConfigure = 'Add Cluster Configure',
  WaitingForConnection = 'Waiting Clusters to Connect',
  AddClusterInstallRobusta = 'Install Robusta on Cluster',
  AddClusterVerifyInstallation = 'Verify installation on Cluster',
  AddClusterPrometheusAndAlertManager = 'Install Prometheus and Alert Manager',
  Invitation = "Invitation",
  NoAccountsFound = "NoAccountsFound",
  Apps = "Services",
  MobileAccessView = 'Mobile Access',
  AppDetails = "Services Details",
  Jobs = 'Jobs',
  JobDetails = 'Jobs Details',
  Nodes = 'Nodes',
  NodeDetails = 'Node Details',
  Comparison = 'Comparison',
  ClusterComparison = 'Cluster Comparison',
  ResourceComparison = 'Resource Comparison',
  NamespaceComparison = 'Namespace Comparison',
  Silences = 'Silences',
  CreateSilence = 'Create Silence',
  MetricsExplorer = 'Metrics Explorer',
  KRR = 'KRR',
  Insights = 'Insights',
  Alerts = 'Alerts',
  SimulateAlert = 'Simulate Alert',
  HolmesGPT = 'HolmesGPT',
  HolmesChat = 'HolmesChat',
  HolmesChatFree = 'HolmesChatFree',
  Clusters = "Cluster Health",
  ClusterHealthDetails = 'Cluster Health Details',
  PlanAndUsage = "Plan & Usage",
  NotFound = "NotFound",
  GlobalTimeline = "Global Timeline",
  AccountAccessRequest = "Account Access Request",
  TrendAnalysis = "TrendAnalysis",
  Settings = 'Settings',
  Error = "Error",
}

/**
 * An abstraction layer for Vue routes.
 */
export class RouterBuilder {
  static getLoginRoute(redirectTo?: string) {
    return { name: RouteName.Login, query: { redirectTo } }
  }

  static getSignUpRoute() {
    return { name: RouteName.SignUp }
  }

  static getInvitationRoute() {
    return { name: RouteName.Invitation }
  }

  static getCreateAccountRoute() {
    return { name: RouteName.CreateAccount, params: { account: supabase.accountName } }
  }

  static getAddClusterRoute() {
    return { name: RouteName.AddClusterConfigure, params: { account: supabase.accountName } }
  }

  static getAddClusterInstallRobustaRoute() {
    return { name: RouteName.AddClusterInstallRobusta, params: { account: supabase.accountName } }
  }

  static getAddClusterPromAlertManagerRoute() {
    return { name: RouteName.AddClusterPrometheusAndAlertManager, params: { account: supabase.accountName } }
  }

  static getAddClusterVerifyRoute() {
    return { name: RouteName.AddClusterVerifyInstallation, params: { account: supabase.accountName } }
  }

  static getNoAccountsFoundRoute() {
    return { name: RouteName.NoAccountsFound }
  }

  static getAccountAccessRequestRoute({ account, account_id }: { account: string, account_id?: string} = { account: ''}) {
    return {
      name: RouteName.AccountAccessRequest,
      ...(account
        ? {
            query: {
              // when using account as the first word some functionality intercept it
              // so this is why this naming so weird
              accName: account,
              accId: account_id
            }
          }
        : [])
    }
  }

  static getMobileViewRoute () {
    return { name: RouteName.MobileAccessView }
  }

  static getAppsRoute() {
    return { name: RouteName.Apps }
  }

  static getClustersRoute() {
    return { name: RouteName.Clusters }
  }

  static getPlanAndUsageRoute() {
    return { name: RouteName.PlanAndUsage }
  }


  static getGlobalTimelineRoute(opts?: {
    findingId?: string
    dates?: DatetimeFilter
    focusedFindingId?: string
    showResolved?: boolean
  }) {
    return {
      name: RouteName.GlobalTimeline,
      query: {
        finding: opts?.findingId,
        [QueryParam.Dates]: opts?.dates && serialize(encodeDatetimeFilter(opts.dates)),
        [QueryParam.Focus]: opts?.focusedFindingId && serialize(opts.focusedFindingId),
        [QueryParam.ShowResolved]: opts?.showResolved && serialize(opts.showResolved)
      }
    }
  }

  static getTrendAnalysisRoute() {
    return {
      name: RouteName.TrendAnalysis
    }
  }
}
